import React from 'react'
import Layout from '../components/Layout'
import ReviewPage from '../components/ReviewPage'
import Team from '../components/Team'
import * as styles from '../styles/mehr.module.css'

export default function Mehr() {
    return (
        <Layout>
            <div className={styles.mehr}>

                <div className={styles.photo}></div>

                <div className={styles.container}>

                    <div className={styles.heading}>
                    <h2>Über uns</h2>
                    </div>

                    <div className={styles.inf}>
                    <p>Ich bin eine mehrsprachige Zahnärztin (Deutsch, Englisch, Bulgarisch und etwas Russisch) und
                    verfüge über eine langjährige Berufserfahrung. Nach dem Studium habe ich im Jahr 2000 zusätzlich 3
                    Jahre an der Universität für Zahnmedizin in Plovdiv gearbeitet und mein Fachzahnarztdiplom
                    gemacht. Seitdem besuche ich regelmäßig Fortbildungen um mich weiter zu qualifizieren.</p>

                    <p>Meine Tätigkeit als Zahnärztin habe ich 1999 in Bulgarien mit meiner eigenen Praxis begonnen.
                    Zwischen 2015 und 2021 arbeitete ich in Berlin als angestellte Zahnärztin in verschiedenen Praxen in
                    Neukölln, Lichtenberg, Prenzlauer Berg und Marzahn-Hellersdorf. Seit April 2021 betreibe ich eine
                    eigene Praxis, die ich vom Frau Ute Henrion übernommen habe.</p>

                    <p>Neben meiner Tätigkeit als Zahnärztin bin ich noch Mutter von 3 Kindern. Ich liebe entspannte
                    Spaziergänge in der Natur, und gehe gerne ins Theater und in Konzerte.</p>

                    <p><i>Gesundheit beginnt im Kopf!</i></p>

                    <p>Also immer positiv denken und in Harmonie mit Mutter Natur leben.</p>

                    <p>Und genauso wie unsere Augen der Spiegel unserer Seele sind, bildet der Zustand des Mundraums
                    den Gesundheitszustand unseres Körpers ab. Es heißt ja auch „Gesundheit beginnt im Mund“. Das
                    Wohlbefinden meiner Patienten ist mir wichtig. Daher empfehle ich Ihnen eine halbjährige Kontrolle
                    und biete Ihnen eine optimale Betreuung.</p>

                    <p>Frau Veneta Nenkova-Bogdanova</p>

                    </div>

                    <div className={styles.team}>
                        <Team />
                    </div>

                    <div className={styles.meinungen}>
                        <ReviewPage />
                    </div>

                </div> 
            </div>

        </Layout>
    )
}
